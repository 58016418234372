.right-part {
  user-select: none;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  width: 20vw;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  z-index: 3;

  h3 {
    color: var(--dark-blue);
  }
}

@import "right_part_xs";