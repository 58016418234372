@media (max-width: 900px) {
  .right-part {
    backdrop-filter: unset;
    user-select: none;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0px;
    overflow-y: visible;
    width: 100vw;
    background-color: transparent;
    padding: 0px;
    z-index: 100;
    position: absolute;

    h3 {
      display: none;
      color: var(--dark-blue);
    }
  }
}
