.news-item {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
  }
  .header-zone {
    display: flex;
    flex-direction: row;
    .news-item-header {
      width: 100%;

      .title-zone {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .likes {
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          font-size: 12px;
          color: var(--gray);
          opacity: 0.8;
          font-weight: 300;
          cursor: pointer;
          i {
            cursor: pointer;
          }

          &:hover {
            color: var(--green-500);
          }
        }
      }

      .time {
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 10px;
        opacity: 0.3;
        color: var(--gray);
      }
      display: flex;
      flex-direction: column;
      padding: 0;
      h3 {
        margin: 0;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0px;
        color: var(--green-500);
      }

      p.lead {
        margin: 0;
        font-size: 12px;
        line-height: 1.6;
        color: var(--gray);
        opacity: 0.8;
        margin-bottom: 9px;
      }

      span {
        color: var(--green-500);
        font-size: 1rem;
        font-weight: 500;
        border: none;
        background: none;
        cursor: pointer;
      }
    }
  }

  .keywords {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    .keyword {
      border-radius: 5px;
      color: var(--green-500);
      font-size: 0.7rem;
      border: 1px solid var(--blue_400);
      padding: 7px;
      padding-bottom: 5px;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  background: var(--dark-gray);
  border-radius: 8px;
  padding: 10px;
  padding-bottom: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .news-item-body {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 18px;
    //background: var(--black);
    //padding: 10px;
    border-radius: 8px;
  }

}