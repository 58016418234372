.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }
.items-start { align-items: flex-start;}
.items-center { align-items: center; }
.items-end { align-items: flex-end; }
.content-start { align-content: flex-start; }
.content-center { align-content: center; }
.content-end { align-content: flex-end; }
.justify-start { justify-content: flex-start; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.justify-end { justify-content: flex-end; }