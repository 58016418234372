$stick-size: 5px;
$stick-border-radius: 2px;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: $stick-border-radius;

  .top-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: $stick-size ridge transparent;
    border-right: $stick-size ridge transparent;
    border-top: $stick-size ridge transparent;
    border-bottom:$stick-border-radius inset transparent;
    border-radius: $stick-border-radius;
  }

  .bottom-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: $stick-size ridge transparent;
    border-right: $stick-size ridge transparent;
    border-top:$stick-border-radius inset transparent;
    border-bottom: $stick-size ridge transparent;
    border-radius:$stick-border-radius;
  }
}

