$num_of_stars: 1000;

@for $i from 1 through $num_of_stars {
  $width: random(12) + 1 + px;
  .star:nth-child(#{$i}) {
    position: fixed;
    opacity: 0;
    top: random(10000) /100 + vh;
    left: random(9900) / 100 + vw;
    border-radius: 50%;
    width: $width;
    height: $width;
    background: #858585;
    box-shadow: 0 0 1px #fff;
    animation: appear 1s ease-in-out;
    animation-delay: #{$i * 0.1}s;
    animation-fill-mode: forwards;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  }
}
