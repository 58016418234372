body, html {
}

body::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
body::-webkit-scrollbar-thumb {
  background: #5b84a1;
}

@import "body-xl";
@import "body-lg";
@import "body-md";
@import "body-sm";
@import "body-xs";

