.registration {
  z-index: 300;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(4px);
  align-items: center;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  color: rgba(255,255,255,0.8);


  .registration-content {
    width: 60vw;
    min-width: 320px;
    background: linear-gradient(45deg, rgba(0,0,0,0.9), rgba(20,10,40,0.9));
    border: 2px solid transparent;
    border-image: linear-gradient(45deg, #0ff, #f0f) 1;
    box-shadow: 0 0 20px rgba(0,255,255,0.3),
                inset 0 0 30px rgba(255,0,255,0.2);
    animation: neonPulse 2s infinite;

    @keyframes neonPulse {
      0% {
        box-shadow: 0 0 20px rgba(0,255,255,0.3),
                    inset 0 0 30px rgba(255,0,255,0.2);
      }
      50% {
        box-shadow: 0 0 40px rgba(0,255,255,0.5),
                    inset 0 0 50px rgba(255,0,255,0.4);
      }
      100% {
        box-shadow: 0 0 20px rgba(0,255,255,0.3),
                    inset 0 0 30px rgba(255,0,255,0.2);
      }
    }
    
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;


    input {
      outline: none;
      &:focus {
        outline: none;
        border-color: var(--sport-lime);
      }
      background: transparent;
      border: 2px solid #ededed;
      margin-bottom: 10px;
      text-align: center;
      color: #ededed;
      margin-bottom: 10px;
      &::placeholder {
        text-align: center;
        color: #ededed;
      }
    }

    button {
      margin-top: 10px;
      margin-left: auto; 
      margin-right: auto;
      width: 50%;
      min-width: 100px;
      background: var(--sport-lime);

      color: var(--black);
    }
  }

}


@media (max-width: 768px) {
  .registration {
    overflow: hidden;
    .registration-content {
      margin: 0;
      position: absolute;
      top: 120px;
      height: auto;

      width: 90%;
      input {
        font-size: 20px;
      }
      button {
        width: 100%;
      }
    }
  }
}
