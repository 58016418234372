.news-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  .news-page-content-circles {
    display: none;
  }

  .news-list {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: var(--white);
    gap: 16px;
    width: 100%;
  }
}

@import "news-page-xs";