.open-source {
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(45deg, var(--black), var(--light-actionSecText), var(--black));
  color: var(--black-white);
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  pointer-events: all;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling in webkit browsers like Safari */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* For Internet Explorer */

  .chat-container {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: red;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
    overflow-y: auto;
    max-height: 250px;
    // border: 2px solid var(--sport-lime);
    user-select: none;
    padding: 13px;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .announcement, .me {
      white-space: pre-line;
      color: var(--sport-lime);
      font-size: 20px;
      line-height: 1.11em;
      margin-bottom: 10px;
    }

    .me {
      margin-left: auto;
      min-width: 200px;
      width: 50%;
      background-color: var(--active-blue);
      padding: 5px;
      border-radius: 5px;
      
      color: var(--dark-black);
    }

    @media (max-width: 900px) {
      max-height: 150px;
      .announcement, .me {
        font-size: 15px;
      }

      .me {
        width: 80%;
      }
    }
  }
  h3 {
    margin-top: 0px;
  }

  button {
    width: 100%;
    margin-top: 10px;
    background: var(--sport-lime);
    color: var(--dark-black);
    font-weight: bold;
  }
}

@media (max-width: 900px) {
  .open-source {
    font-size: 18px;

    button {
      width: 100%;
      margin-top: 10px;
      background: var(--sport-lime);
      color: var(--dark-black);
      font-weight: bold;
    }
  }

}
@media (min-width: 900px) {
  .open-source {
    font-size: 24px;


  }
}

.open-source::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
.open-source::-webkit-scrollbar-thumb {
  background: #5b84a1;
}

@media(max-width: 1568px) {
  .open-source {
    font-size: 16px;
  }
}

@media(max-width: 1100px) {
  .open-source {
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .open-source {
    font-size: 14px;
    ul {
      li {
        line-height: 1.01em;
        font-size: 15px;
      }
    }
  }
}

@media (max-height: 600px) {
  .open-source {
    font-size: 14px;
    line-height: 1.01em;
  }
}