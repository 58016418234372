@media(max-width: 900px) {
  $cube_size_xs: 85vmin;
  $half_cube_size_xs: calc($cube_size_xs / 2);

  .cube {
    pointer-events: all;
    position: relative;
    width: $cube_size_xs;
    height: $cube_size_xs;
    transform-style: preserve-3d;
    transition: 1500ms;
    margin-top: 22vh;
    margin-left: 8vw;
    //border: 3px solid #f00;
    //animation: turn 5s linear infinite;
  }

  .face {
    width: $cube_size_xs;
    height: $cube_size_xs;
    background: #00abff;
    //border: 2px solid rgba(0,0,0,0.5);
    //inset: 10px solid rgb(255,255,255);
    position: absolute;
    //opacity: 0.5;
    display: flex;
    flex-direction: column;
    //padding: 10px;
    //font-size: 2rem;
    transition: transform 500ms;
    overflow: hidden;
    pointer-events: all;

    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }



  .front {
    transform: translateZ($half_cube_size_xs);
  }

  .back {
    transform: translateZ(-$half_cube_size_xs) rotateY(180deg);
  }

  .left {
    transform: translateX(-$half_cube_size_xs) rotateY(-90deg);
  }

  .right {
    transform: translateX($half_cube_size_xs) rotateY(90deg);
  }

  .top {
    transform: translateY(-$half_cube_size_xs) rotateX(90deg);
    overflow-y: scroll;
  }

  .bottom {
    transform: translateY($half_cube_size_xs) rotateX(-90deg);
  }
}