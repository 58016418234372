.grants {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  background: var(--grants-background);
  height: 100%;
  color: var(--white);

  .project {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 5px;
    padding: 15px;
    padding-top: 0px;
    margin-bottom: 45px;
    transition: all 0.3s ease;
    background: rgba(200, 200, 200, 0.1);
    user-select: none;


    

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
      border-color: rgba(255, 255, 255, 0.2);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;
      pointer-events: none;
    }

    p.title {
      position: absolute;
      top: -25px;
      left: 0px;
      background-color: inherit;
      padding: 5px 15px;
      border-radius: 8px 8px 0 0;
      font-size: 15px;
      margin: 0;
      color: rgba(255, 255, 255, 0.8);
      background: linear-gradient(135deg, #00abff 0%, #00abff 40%);
    }

    img {
      margin: 10px;
      margin-left: 0;
      max-width: 90px;
      height: 70px;
      margin-right: 20px;
    }



    .description {
      margin: 0;
      margin-left: auto;
      width: calc(100% - 110px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .lead {
        line-height: 1.2;
        font-size: 14px;
        color: #ffffff;
        opacity: 0.9;
        margin: 0;
      }
      .text {
        display: none;
        font-size: 16px;
        margin: 0;
      }
      color: var(--gray_400); 
    }



    .link {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: var(--gray_300);
      font-size: 20px;
    }


    @media (max-width: 900px) {
      font-size: 16px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 10px;

      img {
        height: 50px;
        max-width: 50px;
        margin-right: 0px;
      }

      .description {
        .lead {
          font-size: 12px;
          margin-right: 30px;
        }
      }

      .link {
        right: 3px;
        opacity: 0.8;
      }
    }
  }

  p {
    margin-top: 30px;
    font-size: 30px;
  }

}