button {
  background: var(--blue_430);
  color: var(--blue_430-text);
  border: none;
  padding: 10px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;

  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);

  transition: transform 0.1s ease-in-out, filter 0.1s ease-in-out;

  &:active {
    transform: scale(0.95) skew(-2deg);
    filter: brightness(1.2) contrast(1.2) drop-shadow(0 0 5px var(--blue_430));
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }
}