$item-bg: linear-gradient(270deg,rgba(73,187,251,.15) 100%,rgba(73,187,251,0) 100%);
.menu-list {
  position: absolute;
  width: 320px;
  display:flex;
  flex-direction:column;
  z-index:1;
  left: 10px;
  top: 14vh;
  border: 1px solid hsla(0,0%,100%,.2);
  border-image: linear-gradient(to right, rgba(73,187,251,0.6), rgba(73,187,251,0.2)) 1;
  border-radius: 10px;
  padding: 0;
  backdrop-filter: blur(5px);

  .item-container {
    cursor:pointer;
    position:relative;
    width: 100%;

    .item {
      position:relative;
      width: 100%;
      height: 70px;
      background: transparent;
      display:flex;
      justify-content:center;
      align-items:center;
      font-size:22px;
      color: #15dcf2;
      i {
        margin-right: auto;
        margin-left: 20px;
        opacity: 0.3;
        // color: #883a e7;
        color: #15dcf2;
      }
      padding-right: 30px;
    }
  }
  .active-item {
    border-radius: 10px;
    font-weight: bold;
    background: $item-bg;
    border: 2px solid var(--active-blue);
    i {
      opacity: 1;
    }
  }
}

@media(max-width: 1000px){
  .menu-list {
    left: 0;
    position: fixed;
    bottom: 20px;
    margin-top: auto;
    width: 100vw;
    height: 50px;
    flex-direction: row;
    overflow: hidden;
    pointer-events: all;
    overflow-x: scroll;
    border: none;
    border-radius: 0;
    .item-container {
      padding: 0;
      .item {
        padding: 0;
        border-radius: 0;
        max-width: unset;
        padding: 0;
        transition: 200ms;
        margin-right: 2px;
        height: 50px;
        margin-left: 2px;
        color: var(--black-white);
        border: none;
        i {
          width: 20px;
          margin-right: 20px;
        }
        padding-right: 10px;
      }
      .active-item {
        i {
          color: var(--white);
          opacity: 1;
        }
        background: var(--active-blue);
      }
    }

    transition: 200ms;
  }

}