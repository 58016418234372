.community {
  padding: 20px;
  padding-top: 0px;
  background: linear-gradient(45deg, var(--black), var(--light-actionSecText), var(--black));
  height: 100%;

  
  p {
    font-size: 22px;
    width: 95%;
    color: var(--black-white);
  }

  input {
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
  }

  .bevel {
    margin-top: 10px;
    margin-bottom: 30px;
    i {
      opacity: 0.5;
      animation: colorChange 33s infinite;
      
      @keyframes colorChange {
        0% {
          color: #ff0000;
        }
        33% {
          color: #00ff00; 
        }
        66% {
          color: #0000ff;
        }
        100% {
          color: #ff0000;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .community {
    padding: 20px;
    padding-top: 0px;
    background: linear-gradient(45deg, var(--black), var(--light-actionSecText), var(--black));
    height: 100%;


    p {
      font-size: 18px;
      line-height: 1.01em;
      width: 100%;
      color: var(--black-white);
    }

    input {
      margin-top: 10px;
      width: 100%;
      font-size: 16px;
    }

    .bevel {
      margin-top: 10px;
      margin-bottom: 30px;
      i {
        opacity: 0.5;
        animation: none;
      }
    }
  }
}