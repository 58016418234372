@media (max-width: 900px) {
  .news-page-content {
    display: flex;
    flex-direction: row;
    pointer-events: all;
    padding: 0;

    .news-list {
      display: none;
    }


    .news-page-content-circles {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      //backdrop-filter: blur(15px);
      gap: 10px;
      padding-top: 5px;
      padding-bottom: 25px;

      .news-page-content-circle {
        position: relative;
        min-width: 80px;
        max-width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: var(--white);
        display: flex;

        img {
          position: absolute;
          border-radius: 50%;
          border: 1px solid var(--sport-lime);
          filter: brightness(0.75);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        p {
          position: absolute;
          top: calc(100% + 2px);
          width: 100%;
          text-align: center;
          margin: 0;
          font-size: 10px;
          color: var(--white);
          line-height: 1.01em;
        }
      }
    }

    h1 {
      display: none;
      font-family: 'Oswald', sans-serif;
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 16px;
      color: var(--white);
      margin-top: 6px;
      margin-bottom: 16px;
      text-align: left;
    }
  }
}
