@import "colors/colors";
@import "layout/layout";
@import "elements/elements";
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Press+Start+2P&display=swap');
* {
  font-family: 'JetBrains Mono', monospace;
  box-sizing: border-box;
  cursor: none;
}

#root {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  &::-webkit-scrollbar {
    width: 9px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent; //rgba(255,255,255,0.03);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: 2px solid #858585;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    //background: #555;
  }
}