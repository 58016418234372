.modal-window {
  position: fixed;
  z-index: 300000;
  background: var(--light-white-alpha-10);
  
  background-repeat: repeat-y repeat-x;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(5px);
  transition: 400ms;
  pointer-events: none;
  opacity: 0;

  .stripes {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-size: 86px 86px !important;
    background-image: url("cool-stripes-small.png");
    pointer-events: none;
    z-index: 3;
  }
}