$cube_size: 70vmin;
$half_cube_size: calc($cube_size / 2);



.cube {
  pointer-events: all;
  position: relative;
  z-index: 1;
  width: $cube_size;
  height: $cube_size;
  transform-style: preserve-3d;
  transition: 1500ms;
  margin-top: 12vh;
  //border: 3px solid #f00;
  //animation: turn 5s linear infinite;
}



.face {
  width: $cube_size;
  height: $cube_size;
  background: var(--black);

  border-image: linear-gradient(45deg, #00f7ff, #ff00e4, #00f7ff) 1;
  //animation: glowPulse 9s ease-in-out infinite;
  box-shadow: 0 0 10px rgba(0, 247, 255, 0.7),
                0 0 20px rgba(255, 0, 228, 0.4),
                inset 0 0 15px rgba(0, 247, 255, 0.6),
                inset 0 0 30px rgba(255, 0, 228, 0.3);

  @keyframes glowPulse {
    0% {
      //box-shadow: 0 0 10px rgba(0, 247, 255, 0.7),
      //           0 0 20px rgba(255, 0, 228, 0.4),
      //           inset 0 0 15px rgba(0, 247, 255, 0.6),
      //           inset 0 0 30px rgba(255, 0, 228, 0.3);
    }
    50% {
      //box-shadow: 0 0 30px rgba(0, 247, 255, 0.9),
      //           0 0 60px rgba(255, 0, 228, 0.6),
      //           inset 0 0 25px rgba(0, 247, 255, 0.8),
      //           inset 0 0 50px rgba(255, 0, 228, 0.5);
    }
    100% {
      box-shadow: 0 0 20px rgba(0, 247, 255, 0.7),
                 0 0 40px rgba(255, 0, 228, 0.4),
                 inset 0 0 15px rgba(0, 247, 255, 0.6),
                 inset 0 0 30px rgba(255, 0, 228, 0.3);
    }
  }
  position: absolute;
  //opacity: 0.5;
  display: flex;
  flex-direction: column;
  //padding: 10px;
  //font-size: 2rem;
  transition: transform 500ms;
  overflow: hidden;
  pointer-events: all;

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}



.front {
  transform: translateZ($half_cube_size);
}

.back {
  transform: translateZ(-$half_cube_size) rotateY(180deg);
}

.left {
  transform: translateX(-$half_cube_size) rotateY(-90deg);
}

.right {
  transform: translateX($half_cube_size) rotateY(90deg);
}

.top {
  transform: translateY(-$half_cube_size) rotateX(90deg);
  overflow-y: scroll;
}

.bottom {
  transform: translateY($half_cube_size) rotateX(-90deg);
}

@import "cube-menu-md";
@import "cube-menu-sm";
@import "cube-menu-xs";
//@media (prefers-reduced-motion: reduce) {
//  .cube {
//
//    transform: rotate3d(1, 1, 0, 45deg);
//  }
//}