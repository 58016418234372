.custom-cursor-field {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  pointer-events: none;
  z-index: 10000000;

  .cursor {
    display: flex;
    border: 1px solid #ededed;
    backdrop-filter: invert(10%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

@media (max-width: 500px) {
  .custom-cursor-field {
    display: none;
  }
}