.article {
  max-height: 90vh;
  background: var(--gray_200);
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 8px;
  color: var(--violet_dark);
  overflow-y: auto;
  max-width: 70vw;
  z-index: 2;
  h1 {
    font-size: 18px;
  }

  p {
    text-align: left;
    color: var(--violet_dark);
  }
}

@media (max-width: 568px) {
  .article {
    width: 90%;
    max-width: unset;
    z-index: 10;
    background: var(--white);

    h2 {
      font-size: 32px;
    }
  }
}