.lawstuff {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  p {
    pointer-events: all;
    color: #fff;
    opacity: 0.7;
    text-align: right;
    text-decoration: none;
    margin-right: 40px;

    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 900px) {
  .lawstuff {
    background: var(--dark-black);
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0vh;
    left: 0px;
    text-align: center;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;

    p {
      font-size: 9px;
      text-align: center;
      text-decoration: none;
      padding: 0px;
      opacity: 0.5;
      margin: 0;
      width: 100%;
    }
  }

}