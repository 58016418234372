.title {
  position: relative;
  text-align: left;
  transform: translateZ(0);
  transform-style: preserve-3d;
  white-space: pre-wrap;
  
}


.title .text {
  font-weight: 400;
  font-size: 62px;
  //font-size: calc(10vw / var(--word-total) );
  line-height: 1.0;
  white-space: pre-wrap;
  
}

@media (max-width: 900px) {
  .title .text {
    display: none;
    font-size: 41px;
  }
}

.title .write .word {
  font-weight: 100;
  //color: var(--sport-lime);
  //color: rgba(200, 200, 200, 0.9);
  color: rgba(145, 232, 181, 0.9);
  opacity: 1;
  //text-shadow: 0 0 0.05em currentColor;
  transform-style: preserve-3d;
  animation: write linear both;
  
}

.title .laser {
  position: absolute;
  top: 0;
  left: 0;
  /* To avoid the blur getting masked by the clip-path we had to duplicate the element */
  filter: blur(4px) contrast(10);
  pointer-events: none;
}

.title .laser .word {
  display: inline-block;
}

.title .laser .word {
  color: hsl(0, 100%, 75%);
  text-shadow: 0 0 0.1em currentColor;
  transform: translateZ(5px);
  animation: laser linear both;
}

.title .write .word,
.title .laser .word {
  animation-duration: 4000ms;
  //animation-delay: calc(0.3s + var(--word-index) * 160ms);
  animation-iteration-count: 1;
}

.title:hover .word {
  animation-play-state: paused;
}

/* .title .text .word,
.title .text .word::before {
  animation-play-state: paused;
  animation-delay: -0.4s;
} */

@keyframes write {
  from, 30% { clip-path: polygon(-20% 0%, -15% 0%, -15% 100%, -20% 100%) }
  70%, to { clip-path: polygon(-15% 0%, 120% 0%, 120% 100%, -15% 100%) }
}

@keyframes laser {
  from, 30% { clip-path: polygon(-20% 0%, -15% 0%, -15% 100%, -20% 100%) }
  70%, to { clip-path: polygon(115% 0%, 120% 0%, 120% 100%, 115% 100%) }
}

.splitted {
  color: #fff;
}

.splitted .char {
  color: #fff;
  //animation: slide-in 3s cubic-bezier(1, 0, 0, 1) infinite;
  animation: slide-in 2s cubic-bezier(1, 0, 0, 1) infinite;
  animation-delay: 0ms; //calc(60ms * var(--char-index));
}

@keyframes slide-in {
  from {
    letter-spacing: 16px;
    transform: translate(6em, calc(50vh + 50%)) scale(0);
    opacity: 0;
  }
  50% {
    letter-spacing: 0px;
    transform: translateX(0em) scale(1);
    opacity: 1;
  }
  to {
    letter-spacing: 16px;
    transform: translate(-6em, calc(50vh + 50%)) scale(0);
    opacity: 0;
  }
}
