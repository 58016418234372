.contests {
  display: flex;
  padding: 20px;
  padding-top: 0;
  flex-direction: column;
  background: var(--contests-back);
  height: 100%;
  color: var(--black-white);

  button.sport {
    background-color: var(--sport-lime);
    color: rgba(0,0,0, 1);
  }
  button.sport-inv {
    color: var(--sport-lime);
    background-color: var(--gray_900_alpha72);
  }

  .upcoming-hackathons {
    background: var(--contests-back);
    position: absolute;
    left: 0;
    pointer-events: all;

    display: flex;
    width: 100%;
    flex-direction: column;
    min-width: 320px;
    margin-left:  0px;
    padding: 0px;
    border-radius: 10px;
    font-size: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    gap: 10px;
    max-height: 500px;
    z-index: 100;

    h3 {
      margin-top: 0;
      color: var(--sport-lime);
    }

    color: var(--black);

    .upcoming-hackathon {
      margin-left: 0px;
      padding: 10px;
      border-radius: 10px;

      border: 2px solid var(--sport-lime);
      box-shadow: 0 0 15px rgba(0, 255, 0, 0.3);
      position: relative;

      button {
        margin-top: 10px;
        background-color: var(--sport-lime);
        color: var(--black);
      }

      h3 {
        color: var(--sport-lime);
        text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
        font-family: monospace;
        margin-bottom: 10px;
        margin-top: 0;
      }

      p {
        color: var(--sport-lime);
        font-family: monospace;
        opacity: 0.8;
      }
    }
  }
}

@media (max-width: 1568px) {
  .contests {
    .upcoming-hackathons {
      width: 100%;
      margin-left: 0px;
      font-size: 15px;
    }
  }
}

@media (max-width: 900px) {
  .contests {
    .upcoming-hackathons {
      width: 90%;
      margin-left: 0px;
      font-size: 15px;
    }
  }
}